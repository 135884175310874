<template>
    <!-- <u-dialog v-model="shows" :title="'타겟 ' + coupon.usage.target.typeText + ' 할인 쿠폰'" width="600"> -->
    <u-dialog v-model="shows">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>

        <div class="tit-wrap tit-wrap--sm">
            <div class="tit tit--xxs">쿠폰정보</div>
        </div>

        <u-vertical-items>
            <u-vertical-item>
                <template #tit> 쿠폰명</template>
                <template #txt> {{ coupon?.name }} </template>
            </u-vertical-item>
            <dialog-price title="혜택" v-bind="{ coupon }" />
            <dialog-period title="유효기간" v-bind="{ coupon }" />

            <u-vertical-item>
                <template #tit> 적용대상</template>
                <template #txt>
                    <div>{{ coupon.usage.target.priceText }}에 적용 가능</div>

                    <div>
                        <span> 다른 쿠폰과 동시 사용 </span>
                        <span v-if="coupon.usage.combinable"> 가능 </span>
                        <span v-else> 불가 </span>
                    </div>

                    <!-- 100원 이상 구매 시<br /> // 미개발 -->
                    <!-- 모든 환경에서 사용 가능<br /> // 미개발 -->
                    <!-- 모든 결제수단에 사용 가능<br /> // 미개발 -->
                    <!-- 유입경로와 무관하게 사용 가능<br /> // 미개발 -->

                    <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.ALLITEMS.value">
                        <div>전체 상품 사용가능</div>
                    </template>
                    <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.PRODUCTS.value">
                        <div>상품 상세 별도표기</div>
                    </template>
                    <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.DISPLAYS.value">
                        <dialog-displays v-bind="{ coupon }" />
                    </template>
                    <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.EXHIBITS.value">
                        <dialog-exhibits v-bind="{ coupon }" />
                    </template>
                    <template v-if="coupon.usage.target.type == USAGE_TARGET_TYPES.CATEGORY.value">
                        <dialog-categories v-bind="{ coupon }" />
                    </template>
                </template>
            </u-vertical-item>
        </u-vertical-items>

        <template v-slot:actions>
            <div class="w-100 mx-auto">
                <v-row no-gutters justify="center">
                    <v-col cols="12" md="auto">
                        <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-sm-140px" @click="shows = false">확인</v-btn>
                    </v-col>
                </v-row>
            </div>
        </template>
    </u-dialog>
</template>

<script>
import { initShopCoupon, USAGE_TARGET_TYPES } from "@/assets/variables";
import { btn_primary } from "@/assets/variables";

import PageSection from "@/components/client/templates/page-section.vue";
import UDialog from "@/components/client/dumb/u-dialog.vue";
import UVerticalItems from "@/components/client/dumb/u-vertical-items.vue";
import UVerticalItem from "@/components/client/dumb/u-vertical-item.vue";

import DialogCategories from "./dialog/dialog-categories.vue";
import DialogDisplays from "./dialog/dialog-displays.vue";
import DialogExhibits from "./dialog/dialog-exhibits.vue";
import DialogPeriod from "./dialog/dialog-period.vue";
import DialogPrice from "./dialog/dialog-price.vue";

export default {
    components: {
        PageSection,
        UDialog,
        UVerticalItems,
        UVerticalItem,

        DialogCategories,
        DialogDisplays,
        DialogExhibits,
        DialogPeriod,
        DialogPrice,
    },
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        btn_primary,

        shows: false,
        USAGE_TARGET_TYPES,
    }),
};
</script>

<style></style>
