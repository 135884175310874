<template>
    <div class="font-size-16 font-size-md-24 font-weight-light">
        {{ coupon.name }}
    </div>
</template>

<script>
import { initShopCoupon } from "@/assets/variables";

export default {
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
};
</script>

<style></style>
