<template>
    <item-dialog v-bind="{ coupon }">
        <template #activator="{ attrs, on }">
            <v-card flat tile class="card-coupon" v-bind="{ ...attrs, loading, disabled }" @click="on.click">
                <v-row no-gutters align="stretch" class="fill-height">
                    <v-col>
                        <v-card outlined rounded="xs" class="card-coupon__left">
                            <div class="card-coupon__left-inner">
                                <item-name v-bind="{ coupon }" class="mb-12px" />
                                <item-price v-bind="{ coupon }" class="mb-2px" />
                                <item-desc v-bind="{ coupon }" />
                            </div>
                        </v-card>
                    </v-col>
                    <v-col cols="auto">
                        <v-card v-bind="{ loading }" rounded="xs" :color="disabled ? 'grey lighten-4' : 'indigo'" class="card-coupon__right" @click.stop.capture.prevent="post">
                            <div class="card-coupon__right-inner">
                                <template v-if="disabled">
                                    <span class="font-size-14 font-weight-bold"> 이미 받은 쿠폰입니다 </span>
                                </template>
                                <template v-else>
                                    <span class="card-coupon__right-txt">DOWNLOAD</span>
                                </template>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </item-dialog>
</template>

<script>
import api from "@/api";
import { initShopCoupon } from "@/assets/variables";

import ItemName from "./item/item-name.vue";
import ItemDesc from "./item/item-desc.vue";
import ItemPrice from "./item/item-price.vue";
import ItemDialog from "./item/item-dialog.vue";
import { mapState } from "vuex";

export default {
    components: {
        ItemName,
        ItemDesc,
        ItemPrice,
        ItemDialog,
    },

    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    data: () => ({
        loading: false,
        issuedCount: 0,
    }),
    computed: {
        ...mapState({ _user: (state) => state?.payload?._user }),
        disabled() {
            const issueCountExceeded = this.coupon.count <= this.issuedCount;
            return issueCountExceeded;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        coupon() {
            this.init();
        },
        _user() {
            this.init();
        },
    },
    methods: {
        async init() {
            if (this.loading) return;
            else this.loading = true;

            this.issuedCount = 0;
            try {
                if (this._user) {
                    const { summary } = await api.v1.me.coupons.gets({
                        headers: { mode: "summary" },
                        params: { _coupon: this.coupon._id },
                    });
                    this.issuedCount = summary.totalCount;
                }
            } finally {
                if (this.loading) this.loading = false;
            }
        },

        async post() {
            if (this.loading) return;
            else this.loading = true;

            try {
                const { coupon } = await api.v1.me.coupons.post({
                    _coupon: this.coupon._id,
                });
                this.issuedCount += 1;
                this.loading = false;
                this.init();
            } finally {
                if (this.loading) this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.card-coupon {
    overflow: hidden;
    &.v-card--disabled {
        pointer-events: auto;
        user-select: auto;
        cursor: pointer;
        opacity: 0.3;
        & > :not(.v-card__progress) {
            opacity: 1;
        }
        &.theme--light {
            &.v-card {
                color: var(--default-color);
            }
            &.v-sheet--outlined {
                border: 1px solid var(--border-color);
            }
        }
    }
    &__left {
        height: 100%;
        overflow: hidden;
        &-inner {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
            padding: 20px 30px;
        }
    }
    &__right {
        height: 100%;
        width: 60px;
        &-inner {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            width: 100%;
            height: 100%;
            padding: 12px;
            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0;
                z-index: 1;
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                border: 1px solid var(--border-color);
                background-color: #fff;
            }
            &::before {
                top: 0;
                -webkit-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
            &::after {
                bottom: 0;
                -webkit-transform: translate(-50%, 50%);
                -ms-transform: translate(-50%, 50%);
                transform: translate(-50%, 50%);
            }
        }
        &-txt {
            display: flex;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.5em;
            font-family: var(--font-secondary);
            color: #fff;
            transform: rotate(90deg);
            margin-bottom: -0.5em;
        }
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .card-coupon {
        &__right {
            width: 80px;
        }
    }
}
@media (min-width: 1024px) {
    .card-coupon {
        &__left {
            &-inner {
                padding: 30px 40px;
            }
        }
    }
}
@media (min-width: 1200px) {
}
</style>
