<template>
    <div>
        <template v-if="limit">
            <div class="font-size-14 font-size-md-16 font-weight-medium mt-2px">{{ limit }}</div>
        </template>
        <template v-if="usageTargetType">
            <div class="font-size-14 font-size-md-16 font-weight-medium mt-2px">{{ usageTargetType }}</div>
        </template>
        <template v-if="usageTargetPrice">
            <div class="font-size-14 font-size-md-16 font-weight-medium mt-2px">{{ usageTargetPrice }}</div>
        </template>
        <template v-if="usageCombinable">
            <div class="font-size-14 font-size-md-16 font-weight-medium mt-2px">{{ usageCombinable }}</div>
        </template>
        <template v-if="mode == 'shop-coupons'">
            <div v-if="issueDuration" class="pt-4px">
                <div class="font-size-12 grey--text mt-2px">{{ issueDuration }}</div>
            </div>
        </template>
        <template v-if="mode == 'user-coupons'">
            <div v-if="usageDuration" class="pt-4px">
                <div class="font-size-12 grey--text mt-2px">{{ usageDuration }}</div>
            </div>
        </template>
    </div>
</template>

<script>
import { initShopCoupon, USAGE_TARGET_PRICES, USAGE_TARGET_TYPES } from "@/assets/variables";
export default {
    props: {
        coupon: { type: Object, default: initShopCoupon },
        mode: { type: String, default: "shop-coupons" },
    },

    computed: {
        limit() {
            if (this.coupon.limit) return `최대 ${this.coupon.limit?.format?.()}원 할인`;
        },
        usageTargetType() {
            if (this.coupon.usage.target.type == USAGE_TARGET_TYPES.ALLITEMS.value) {
                return "전체 상품 할인쿠폰";
            }
            let text = USAGE_TARGET_TYPES[this.coupon.usage.target.type]?.text;
            if (text) return `타겟 ${text} 할인쿠폰`;
        },
        usageTargetPrice() {
            let text = USAGE_TARGET_PRICES[this.coupon.usage.target.price]?.text;
            if (text) return `${text} 적용가능`;
        },
        issueDuration() {
            const { startsAt, endsAt } = this.coupon?.issue || {};
            if (startsAt || endsAt) {
                let text = "";

                if (startsAt) text += this.$dayjs(startsAt).startOf("day").toISOString().toDateTime();
                text += " ~ ";
                if (endsAt) text += this.$dayjs(endsAt).endOf("day").toISOString().toDateTime();

                return text.trim();
            }
        },
        usageDuration() {
            const { startsAt, endsAt } = this.coupon?.usage || {};
            if (startsAt || endsAt) {
                let text = "";

                if (startsAt) text += this.$dayjs(startsAt).startOf("day").toISOString().toDateTime();
                text += " ~ ";
                if (endsAt) text += this.$dayjs(endsAt).endOf("day").toISOString().toDateTime();

                return text.trim();
            }
            return "사용기한 없음";
        },
        usageCombinable() {
            switch (this.coupon?.usage?.combinable) {
                case true: {
                    return "중복사용 가능";
                }
                default: {
                    return "중복사용 불가";
                }
            }
        },
    },
};
</script>

<style>
</style>