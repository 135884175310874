var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.limit ? [_c('div', {
    staticClass: "font-size-14 font-size-md-16 font-weight-medium mt-2px"
  }, [_vm._v(_vm._s(_vm.limit))])] : _vm._e(), _vm.usageTargetType ? [_c('div', {
    staticClass: "font-size-14 font-size-md-16 font-weight-medium mt-2px"
  }, [_vm._v(_vm._s(_vm.usageTargetType))])] : _vm._e(), _vm.usageTargetPrice ? [_c('div', {
    staticClass: "font-size-14 font-size-md-16 font-weight-medium mt-2px"
  }, [_vm._v(_vm._s(_vm.usageTargetPrice))])] : _vm._e(), _vm.usageCombinable ? [_c('div', {
    staticClass: "font-size-14 font-size-md-16 font-weight-medium mt-2px"
  }, [_vm._v(_vm._s(_vm.usageCombinable))])] : _vm._e(), _vm.mode == 'shop-coupons' ? [_vm.issueDuration ? _c('div', {
    staticClass: "pt-4px"
  }, [_c('div', {
    staticClass: "font-size-12 grey--text mt-2px"
  }, [_vm._v(_vm._s(_vm.issueDuration))])]) : _vm._e()] : _vm._e(), _vm.mode == 'user-coupons' ? [_vm.usageDuration ? _c('div', {
    staticClass: "pt-4px"
  }, [_c('div', {
    staticClass: "font-size-12 grey--text mt-2px"
  }, [_vm._v(_vm._s(_vm.usageDuration))])]) : _vm._e()] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }