var render = function render(){
  var _vm$$attrs;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', _vm._b({
    class: _vm.rowSize
  }, 'v-row', (_vm$$attrs = _vm.$attrs) === null || _vm$$attrs === void 0 ? void 0 : _vm$$attrs.rowProps, false), [this.$slots['tit'] ? _c('v-col', _vm._b({
    class: _vm.titClass
  }, 'v-col', _vm.titProps, false), [_c('div', {
    staticClass: "txt font-weight-medium",
    class: [_vm.titSize, _vm.titColor, _vm.titContentClass, _vm.titContentWidth],
    style: _vm.titContentStyle
  }, [_vm._t("tit")], 2)]) : _vm._e(), this.$slots['txt'] && !_vm.borderNone ? _c('v-col', _vm._b({
    staticClass: "d-none d-md-block ml-n1px",
    attrs: {
      "cols": "auto"
    }
  }, 'v-col', _vm.txtProps, false), [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1) : _vm._e(), this.$slots['txt'] ? _c('v-col', _vm._b({
    class: _vm.txtClass
  }, 'v-col', _vm.txtProps, false), [_c('div', {
    staticClass: "txt",
    class: [_vm.txtSize, _vm.txtColor, _vm.txtContentClass]
  }, [_vm._t("txt")], 2)]) : _c('v-col', _vm._b({
    class: _vm.contentClass
  }, 'v-col', _vm.txtProps, false), [_vm._t("default")], 2)], 1), _c('v-divider')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }