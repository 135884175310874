<template>
    <div>
        <v-row v-bind="$attrs?.rowProps" :class="rowSize">
            <v-col v-if="this.$slots['tit']" v-bind="titProps" :class="titClass">
                <div class="txt font-weight-medium" :class="[titSize, titColor, titContentClass, titContentWidth]" :style="titContentStyle">
                    <slot name="tit" />
                </div>
            </v-col>
            <v-col v-if="this.$slots['txt'] && !borderNone" v-bind="txtProps" cols="auto" class="d-none d-md-block ml-n1px"><v-divider vertical /></v-col>
            <v-col v-if="this.$slots['txt']" v-bind="txtProps" :class="txtClass">
                <div class="txt" :class="[txtSize, txtColor, txtContentClass]">
                    <slot name="txt" />
                </div>
            </v-col>
            <v-col v-else v-bind="txtProps" :class="contentClass">
                <slot />
            </v-col>
        </v-row>
        <v-divider />
    </div>
</template>

<script>
export default {
    props: {
        rowSize: { type: String, default: "no-gutters" },

        titProps: {
            type: Object,
            default: () => ({
                cols: "auto",
            }),
        },
        titClass: { type: String },
        titSize: { type: String, default: "txt--sm" },
        titColor: { type: String, default: "txt--dark" },
        titContentClass: { type: String, default: "text-center py-10px px-12px py-md-16px" },
        titContentWidth: { type: String, default: "w-80px w-md-90px" },
        titContentStyle: { type: String },

        txtProps: {
            type: Object,
            default: () => ({
                cols: "",
            }),
        },
        txtClass: { type: String },
        txtSize: { type: String, default: "txt--sm" },
        txtColor: { type: String },
        txtContentClass: { type: String, default: "py-10px px-12px py-md-16px" },

        contentClass: { type: String },
        borderNone: { type: Boolean },
    },
    components: {},
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>

<style lang="scss" scoped></style>
