var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-vertical-item', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.title) + " ")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm.coupon.value.format()) + _vm._s(_vm.unitText) + " 할인")]), _vm.coupon.limit ? _c('span', [_vm._v(" (최대 할인금액 " + _vm._s(_vm.coupon.limit.format()) + "원) ")]) : _vm._e()];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }