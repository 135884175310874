var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('item-dialog', _vm._b({
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-card', _vm._b({
          staticClass: "card-coupon",
          attrs: {
            "flat": "",
            "tile": ""
          },
          on: {
            "click": on.click
          }
        }, 'v-card', Object.assign({}, attrs, {
          loading: _vm.loading,
          disabled: _vm.disabled
        }), false), [_c('v-row', {
          staticClass: "fill-height",
          attrs: {
            "no-gutters": "",
            "align": "stretch"
          }
        }, [_c('v-col', [_c('v-card', {
          staticClass: "card-coupon__left",
          attrs: {
            "outlined": "",
            "rounded": "xs"
          }
        }, [_c('div', {
          staticClass: "card-coupon__left-inner"
        }, [_c('item-name', _vm._b({
          staticClass: "mb-12px"
        }, 'item-name', {
          coupon: _vm.coupon
        }, false)), _c('item-price', _vm._b({
          staticClass: "mb-2px"
        }, 'item-price', {
          coupon: _vm.coupon
        }, false)), _c('item-desc', _vm._b({}, 'item-desc', {
          coupon: _vm.coupon
        }, false))], 1)])], 1), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-card', _vm._b({
          staticClass: "card-coupon__right",
          attrs: {
            "rounded": "xs",
            "color": _vm.disabled ? 'grey lighten-4' : 'indigo'
          },
          on: {
            "!click": function ($event) {
              $event.stopPropagation();
              $event.preventDefault();
              return _vm.post.apply(null, arguments);
            }
          }
        }, 'v-card', {
          loading: _vm.loading
        }, false), [_c('div', {
          staticClass: "card-coupon__right-inner"
        }, [_vm.disabled ? [_c('span', {
          staticClass: "font-size-14 font-weight-bold"
        }, [_vm._v(" 이미 받은 쿠폰입니다 ")])] : [_c('span', {
          staticClass: "card-coupon__right-txt"
        }, [_vm._v("DOWNLOAD")])]], 2)])], 1)], 1)], 1)];
      }
    }])
  }, 'item-dialog', {
    coupon: _vm.coupon
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }