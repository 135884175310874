var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-dialog', {
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }, {
      key: "actions",
      fn: function () {
        return [_c('div', {
          staticClass: "w-100 mx-auto"
        }, [_c('v-row', {
          attrs: {
            "no-gutters": "",
            "justify": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "12",
            "md": "auto"
          }
        }, [_c('v-btn', _vm._b({
          staticClass: "v-size--xx-large w-100 min-w-sm-140px",
          on: {
            "click": function ($event) {
              _vm.shows = false;
            }
          }
        }, 'v-btn', Object.assign({}, _vm.btn_primary), false), [_vm._v("확인")])], 1)], 1)], 1)];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--sm"
  }, [_c('div', {
    staticClass: "tit tit--xxs"
  }, [_vm._v("쿠폰정보")])]), _c('u-vertical-items', [_c('u-vertical-item', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 쿠폰명")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        var _vm$coupon;
        return [_vm._v(" " + _vm._s((_vm$coupon = _vm.coupon) === null || _vm$coupon === void 0 ? void 0 : _vm$coupon.name) + " ")];
      },
      proxy: true
    }])
  }), _c('dialog-price', _vm._b({
    attrs: {
      "title": "혜택"
    }
  }, 'dialog-price', {
    coupon: _vm.coupon
  }, false)), _c('dialog-period', _vm._b({
    attrs: {
      "title": "유효기간"
    }
  }, 'dialog-period', {
    coupon: _vm.coupon
  }, false)), _c('u-vertical-item', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" 적용대상")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        return [_c('div', [_vm._v(_vm._s(_vm.coupon.usage.target.priceText) + "에 적용 가능")]), _c('div', [_c('span', [_vm._v(" 다른 쿠폰과 동시 사용 ")]), _vm.coupon.usage.combinable ? _c('span', [_vm._v(" 가능 ")]) : _c('span', [_vm._v(" 불가 ")])]), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.ALLITEMS.value ? [_c('div', [_vm._v("전체 상품 사용가능")])] : _vm._e(), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.PRODUCTS.value ? [_c('div', [_vm._v("상품 상세 별도표기")])] : _vm._e(), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.DISPLAYS.value ? [_c('dialog-displays', _vm._b({}, 'dialog-displays', {
          coupon: _vm.coupon
        }, false))] : _vm._e(), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.EXHIBITS.value ? [_c('dialog-exhibits', _vm._b({}, 'dialog-exhibits', {
          coupon: _vm.coupon
        }, false))] : _vm._e(), _vm.coupon.usage.target.type == _vm.USAGE_TARGET_TYPES.CATEGORY.value ? [_c('dialog-categories', _vm._b({}, 'dialog-categories', {
          coupon: _vm.coupon
        }, false))] : _vm._e()];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }