<template>
    <div class="indigo--text">
        <strong class="font-size-36 font-size-md-40 font-size-lg-48 font-secondary">{{ coupon.value.format?.() }}{{ unit }}</strong>
        <strong class="font-size-16 font-size-md-20 font-weight-medium pl-8px">할인</strong>
        <template v-if="1 < this.coupon.count">
            <span class="px-4px">×</span>
            <strong class="font-size-14">{{ coupon.count.format?.() }}</strong>
            <small class="font-size-14 pl-2px">장</small>
        </template>
    </div>
</template>

<script>
import { initShopCoupon, SHOP_COUPON_UNITS } from "@/assets/variables";

export default {
    props: {
        coupon: { type: Object, default: initShopCoupon },
    },
    computed: {
        unit() {
            return SHOP_COUPON_UNITS[this.coupon.unit]?.suffix;
        },
    },
};
</script>

<style>
</style>