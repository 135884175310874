var render = function render(){
  var _vm$coupon$value$form, _vm$coupon$value, _vm$coupon$count$form, _vm$coupon$count;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "indigo--text"
  }, [_c('strong', {
    staticClass: "font-size-36 font-size-md-40 font-size-lg-48 font-secondary"
  }, [_vm._v(_vm._s((_vm$coupon$value$form = (_vm$coupon$value = _vm.coupon.value).format) === null || _vm$coupon$value$form === void 0 ? void 0 : _vm$coupon$value$form.call(_vm$coupon$value)) + _vm._s(_vm.unit))]), _c('strong', {
    staticClass: "font-size-16 font-size-md-20 font-weight-medium pl-8px"
  }, [_vm._v("할인")]), 1 < this.coupon.count ? [_c('span', {
    staticClass: "px-4px"
  }, [_vm._v("×")]), _c('strong', {
    staticClass: "font-size-14"
  }, [_vm._v(_vm._s((_vm$coupon$count$form = (_vm$coupon$count = _vm.coupon.count).format) === null || _vm$coupon$count$form === void 0 ? void 0 : _vm$coupon$count$form.call(_vm$coupon$count)))]), _c('small', {
    staticClass: "font-size-14 pl-2px"
  }, [_vm._v("장")])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }