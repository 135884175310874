<template>
    <u-vertical-item>
        <template #tit> {{ title }} </template>
        <template #txt>
            <span>{{ coupon.value.format() }}{{ unitText }} 할인</span>
            <span v-if="coupon.limit"> (최대 할인금액 {{ coupon.limit.format() }}원) </span>
        </template>
    </u-vertical-item>
</template>

<script>
import { initShopCoupon, SHOP_COUPON_UNITS } from "@/assets/variables";

import UVerticalItem from "@/components/client/dumb/u-vertical-item.vue";

export default {
    components: {
        UVerticalItem,
    },
    props: {
        title: { type: String, default: "혜택" },
        coupon: { type: Object, default: initShopCoupon },
    },
    computed: {
        unitText() {
            return SHOP_COUPON_UNITS[this.coupon.unit].suffix;
        },
    },
};
</script>

<style></style>
