var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('u-vertical-item', {
    scopedSlots: _vm._u([{
      key: "tit",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.title) + " ")];
      },
      proxy: true
    }, {
      key: "txt",
      fn: function () {
        var _vm$coupon$usage$star, _vm$coupon$usage$star2, _vm$coupon$usage$star3, _vm$coupon$usage$ends, _vm$coupon$usage$ends2, _vm$coupon$usage$ends3;
        return [!!_vm.coupon.usage.startsAt || !!_vm.coupon.usage.endsAt ? [_c('span', [_vm._v(_vm._s(((_vm$coupon$usage$star = _vm.coupon.usage.startsAt) === null || _vm$coupon$usage$star === void 0 ? void 0 : (_vm$coupon$usage$star2 = _vm$coupon$usage$star.toDate) === null || _vm$coupon$usage$star2 === void 0 ? void 0 : (_vm$coupon$usage$star3 = _vm$coupon$usage$star2.call(_vm$coupon$usage$star)) === null || _vm$coupon$usage$star3 === void 0 ? void 0 : _vm$coupon$usage$star3.replaceAll("-", ".")) || ""))]), _c('span', [_vm._v(" ~ ")]), _c('span', [_vm._v(_vm._s(((_vm$coupon$usage$ends = _vm.coupon.usage.endsAt) === null || _vm$coupon$usage$ends === void 0 ? void 0 : (_vm$coupon$usage$ends2 = _vm$coupon$usage$ends.toDate) === null || _vm$coupon$usage$ends2 === void 0 ? void 0 : (_vm$coupon$usage$ends3 = _vm$coupon$usage$ends2.call(_vm$coupon$usage$ends)) === null || _vm$coupon$usage$ends3 === void 0 ? void 0 : _vm$coupon$usage$ends3.replaceAll("-", ".")) || ""))]), !!_vm.coupon.usage.endsAt ? [_c('span', [_vm._v(" (" + _vm._s(_vm.$dayjs(_vm.coupon.usage.endsAt).endOf("day").toISOString().toTil()) + ") ")])] : _vm._e()] : [_c('span', [_vm._v(" 유효기간 없음 ")])]];
      },
      proxy: true
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }