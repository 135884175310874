<template>
    <u-vertical-item>
        <template #tit> {{ title }} </template>
        <template #txt>
            <template v-if="!!coupon.usage.startsAt || !!coupon.usage.endsAt">
                <span>{{ coupon.usage.startsAt?.toDate?.()?.replaceAll("-", ".") || "" }}</span>
                <span> ~ </span>
                <span>{{ coupon.usage.endsAt?.toDate?.()?.replaceAll("-", ".") || "" }}</span>
                <template v-if="!!coupon.usage.endsAt">
                    <span> ({{ $dayjs(coupon.usage.endsAt).endOf("day").toISOString().toTil() }}) </span>
                </template>
            </template>
            <template v-else>
                <span> 유효기간 없음 </span>
            </template>
        </template>
    </u-vertical-item>
</template>

<script>
import { initShopCoupon } from "@/assets/variables";

import UVerticalItem from "@/components/client/dumb/u-vertical-item.vue";

export default {
    components: {
        UVerticalItem,
    },
    props: {
        title: { type: String, default: "유효기간" },
        coupon: { type: Object, default: initShopCoupon },
    },
};
</script>

<style></style>
